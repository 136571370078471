import Vue from 'vue';
import api from '../../api';

let axis;
try {
  axis = JSON.parse(localStorage.getItem('hardware-graph-axis'));
} catch (e) {
  axis = {};
}
export const hardwareModule = {
  namespaced: true,

  state: {
    motors: [],
    encoders: [],
    drives: [],
    gearboxes: [],
    axis,

    motorsIndex: new Map(),
    encodersIndex: new Map(),
    drivesIndex: new Map(),
    gearboxesIndex: new Map(),

    motorsLoading: true,
    encodersLoading: true,
    drivesLoading: true,
    gearboxesLoading: true,

    editedItem: {},
  },

  getters: {
    // TODO: This shouldn't need to be done.
    // At the very least there should be a switch here to only pass back "approved" state.
    getStateByType: (state) => (type) => {
      return state[type];
    },

    getXAxis: (state) => (type) => state.axis?.[type]?.x,
    getYAxis: (state) => (type) => state.axis?.[type]?.y,
    getRAxis: (state) => (type) => state.axis?.[type]?.r,
    getNameDisplay: (state) => (type) => state.axis?.[type]?.name,
  },

  actions: {
    editItem({commit}, item) {
      commit('setEditedItem', item);
      commit('setDialog', true, {root: true});
    },

    async hardwareAdd(unused, {hardwareType, hardware}) {
      return api.addHardware(hardwareType, hardware);
    },

    async hardwareEdit(unused, {hardwareType, id, changes}) {
      return api.editHardware(hardwareType, id, changes);
    },

    hardwareDelete(unused, {hardwareType, hardware}) {
      return api.deleteHardware(hardwareType, hardware);
    },

    saveAxis({commit}, {axis, type, value}) {
      commit('setAxis', {axis, type, value});
    },
  },

  mutations: {
    setEditedItem(state, item) {
      state.editedItem = item;
    },

    setAxis(state, {axis, type, value}) {
      state.axis = {
        ...state.axis,
        [type]: {
          ...state.axis?.[type],
          [axis]: value,
        },
      };
      localStorage.setItem('hardware-graph-axis', JSON.stringify(state.axis));
    },

    // ----------------------------
    // Socket Messages
    // ----------------------------
    SOCKET_MOTORS_SET_ALL(state, motors) {
      state.motorsLoading = false;
      state.motors = motors;
      state.motorsIndex = new Map(motors.map((motor) => [motor.id, motor]));
    },
    SOCKET_ENCODERS_SET_ALL(state, encoders) {
      state.encodersLoading = false;
      state.encoders = encoders;
      state.encodersIndex = new Map(
          encoders.map((encoder) => [encoder.id, encoder]),
      );
    },
    SOCKET_DRIVES_SET_ALL(state, drives) {
      state.drivesLoading = false;
      state.drives = drives;
      state.drivesIndex = new Map(drives.map((drive) => [drive.id, drive]));
    },
    SOCKET_GEARBOXES_SET_ALL(state, gearboxes) {
      state.gearboxesLoading = false;
      state.gearboxes = gearboxes;
      state.gearboxesIndex = new Map(
          gearboxes.map((gearbox) => [gearbox.id, gearbox]),
      );
    },

    SOCKET_MOTOR_CREATED(state, motor) {
      state.motors.push(motor);
      state.motorsIndex.set(motor.id, motor);
    },
    SOCKET_ENCODER_CREATED(state, encoder) {
      state.encoders.push(encoder);
      state.encodersIndex.set(encoder.id, encoder);
    },
    SOCKET_DRIVE_CREATED(state, drive) {
      state.drives.push(drive);
      state.drivesIndex.set(drive.id, drive);
    },
    SOCKET_GEARBOX_CREATED(state, gearbox) {
      state.gearboxes.push(gearbox);
      state.gearboxesIndex.set(gearbox.id, gearbox);
    },

    SOCKET_MOTOR_UPDATED(state, motor) {
      const index = state.motors.findIndex((i) => i.id === motor.id);
      if (index === -1) {
        console.log('Could not find motor', motor);
        return;
      }
      Vue.set(state.motors, index, motor);
    },
    SOCKET_ENCODER_UPDATED(state, encoder) {
      const index = state.encoders.findIndex((i) => i.id === encoder.id);
      if (index === -1) {
        console.log('Could not find encoder', encoder);
        return;
      }
      Vue.set(state.encoders, index, encoder);
    },
    SOCKET_DRIVE_UPDATED(state, drive) {
      const index = state.drives.findIndex((i) => i.id === drive.id);
      if (index === -1) {
        console.log('Could not find drive', drive);
        return;
      }
      Vue.set(state.drives, index, drive);
    },
    SOCKET_GEARBOX_UPDATED(state, gearbox) {
      const index = state.gearboxes.findIndex((i) => i.id === gearbox.id);
      if (index === -1) {
        console.log('Could not find gearbox', gearbox);
        return;
      }
      Vue.set(state.gearboxes, index, gearbox);
    },

    SOCKET_MOTOR_DELETED(state, motor) {
      const index = state.motors.findIndex((i) => i.id === motor.id);
      if (index === -1) {
        console.log('Could not find motor', motor);
        return;
      }
      state.motorsIndex.delete(motor.id);
      Vue.delete(state.motors, index);
    },
    SOCKET_ENCODER_DELETED(state, encoder) {
      const index = state.encoders.findIndex((i) => i.id === encoder.id);
      if (index === -1) {
        console.log('Could not find encoder', encoder);
        return;
      }
      state.encodersIndex.delete(encoder.id);
      Vue.delete(state.encoders, index);
    },
    SOCKET_DRIVE_DELETED(state, drive) {
      const index = state?.c?.findIndex((i) => i.id === drive.id);
      if (index !== -1 && index === undefined) {
        return;
      }
      if (index === -1) {
        console.log('Could not find drive', drive);
        return;
      }
      state.drivesIndex.delete(drive.id);
      Vue.delete(state.drives, index);
    },
    SOCKET_GEARBOXE_DELETED(state, gearbox) {
      const index = state.gearboxes.findIndex((i) => i.id === gearbox.id);
      if (index === -1) {
        console.log('Could not find gearbox', gearbox);
        return;
      }
      state.gearboxesIndex.delete(gearbox.id);
      Vue.delete(state.gearboxes, index);
    },
  },
};
