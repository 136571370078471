import Vue from 'vue';
import api from '../../api';


export const assetsModule = {
  namespaced: true,

  state: {
    assets: [],
  },


  actions: {
    async assetsGetAll({commit} ) {
      return api.getAssetsAll()
          .then((response) => {
            commit('setAssets', response.data);
          }).catch((error) => {
            commit('pushError', error);
          });
    },

    async assetAdd({commit}, asset) {
      let response;
      try {
        response = await api.addAsset(asset);
      } catch (error) {
        commit('pushError', error);
        return undefined;
      }

      const assetDto = response.data;
      if (assetDto.isNew) {
        commit('addAsset', assetDto.latestAsset);
      }

      commit('addAsset', assetDto.asset);
      return assetDto;
    },

    async assetEdit( {commit}, {id, changes}) {
      return api.editAsset(id, changes)
          .then((response) => {
            commit('editAsset', response.data);
          }).catch((error) => {
            commit('pushError', error);
          });
    },

    async assetsEdit( {commit}, changes) {
      return api.editAssets(changes)
          .then((response) => {
            commit('setAssets', response.data);
            return true;
          }).catch((error) => {
            console.log(error);
            commit('pushError', error);
            return false;
          });
    },

    async assetDelete( {commit}, asset) {
      return api.deleteAsset(asset)
          .then(() => {
            commit('deleteAsset', asset);
          }).catch((error) => {
            commit('pushError', error);
          });
    },
  },


  mutations: {
    setAssets(state, assets) {
      state.assets = assets;
    },

    addAsset(state, assets) {
      state.assets.push(assets);
    },

    editAsset(state, asset) {
      const index = state.assets.findIndex((i) => i.id == asset.id);
      if (index === -1) {
        console.log('Could not find asset', asset);
        return;
      }
      Vue.set(state.assets, index, asset);
    },

    deleteAsset(state, asset) {
      const index = state.assets.findIndex((i) => i.id == asset.id);
      if (index === -1) {
        console.log('Could not find asset', asset);
        return;
      }
      Vue.delete(state.assets, index);
    },


    // ----------------------------
    // Socket Messages
    // ----------------------------
    SOCKET_ASSETS_SET_ALL(state, assets) {
      state.assets = assets;
    },
    SOCKET_ASSET_CREATED(state, asset) {
      state.assets.push(asset);
    },
    SOCKET_ASSETS_UPDATED(state, assets) {
      for (const asset of assets) {
        const index = state.assets.findIndex((i) => i.id == asset.id);
        if (index === -1) {
          console.log('Could not find asset', asset);
          continue;
        }
        Vue.set(state.assets, index, asset);
      }
    },
    SOCKET_ASSET_DELETED(state, asset) {
      const index = state.assets.findIndex((i) => i.id == asset.id);
      if (index === -1) {
        console.log('Could not find actuator', asset);
        return;
      }
      Vue.delete(state.assets, index);
    },
  },
};
