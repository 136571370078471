import Vue from 'vue';
import Vuetify from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';
import 'katex/dist/katex.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#0079BC',
        info: '#0079BC',
        anchor: '#0079BC',
        warning: '#D27408',
        success: '#47A054',
        alertIcon: '#D73F22',
      },
    },
  },
});
