import Vue from 'vue';
import {datadogRum} from '@datadog/browser-rum';
import api from '../../api';
import {USER_FORMULAS, Role} from '@animtools/rdx-common';

export const usersModule = {
  namespaced: true,

  state: {
    users: [],
    usersLoading: false,
    user: {},
    isAdmin: false,
    isHardwareApprover: false,
    isFigureAdmin: false,
    isProjectAdmin: false,
    editingUser: null,
  },

  actions: {
    async getUsers({commit}) {
      commit('setUsersLoading', true);
      try {
        const response = await api.getUsers();
        commit('setUsers', response.data);
      } catch (error) {
        commit('pushError', error, {root: true});
        throw error;        
      } finally {
        commit('setUsersLoading', false);
      }
    },

    async loadNewEditingUser({ commit, state }, userId) {
      if (userId === state.editingUser?.id) {
        return;
      }
      try {
        const response = await api.getUser(userId);
        commit('setEditingUser', response.data);
      } catch (error) {
        commit('pushError', error, {root: true});
        throw error;        
      }
    },

    async addUser({commit, dispatch}, user) {
      try {
        await api.addUser(user);
        dispatch('getUsers');
      } catch (error) {
        commit('pushError', error, {root: true});
        throw error;
      }
    },

    async editUser({commit, state}, user) {
      try {
        await api.editUser(user);
        commit('editUser', user);
        if (state.user.sapId === user.sapId) {
          commit('setUser', user);
        }
      } catch (error) {
        commit('pushError', error, {root: true});
        throw error;
      }
    },

    async insertFavorite({commit}, figureUuid) {
      try {
        const response = await api.insertFavorite(figureUuid);
        commit('setFavorites', response.data.favorites);
      } catch (error) {
        commit('pushError', error, {root: true});
        throw error;
      }
    },

    async removeFavorite({commit}, figureUuid) {
      try {
        const response = await api.removeFavorite(figureUuid);
        commit('setFavorites', response.data.favorites);
      } catch (error) {
        commit('pushError', error, {root: true});
        throw error;
      }
    },
    canEditFigure({state}, figure) {
      return USER_FORMULAS.canEditFigure(figure, state.user);
    },
    canAdminFigure({state}, figure) {
      return USER_FORMULAS.canAdminFigure(figure, state.user);
    },
    canAdminProject({state}, projectName) {
      return USER_FORMULAS.canAdminProject(projectName, state.user);
    },

    async loadCurrentUser({state, commit, dispatch}) {
      if (state.user.id) {
        return;
      }
      await api.getCurrentUserInfo()
      .then(response => {
        this.loading = false;
        commit('setUser', response.data);
        dispatch('astrolabe/getAstrolabeProfiles', [response.data.sapId], { root: true });
        dispatch('fields/fieldTooltipsGet', null, { root: true });
      });
    }
  },

  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
    setUsersLoading(state, loading) {
      state.usersLoading = loading;
    },
    editUser(state, user) {
      const userIndex = state.users.findIndex((i) => i.id == user.id);
      if (userIndex == -1) {
        //This probably just means we haven't loaded the user list yet, so nothing to update
        return;
      }

      Vue.set(state.users, userIndex, user);
    },

    setUser(state, user) {
      state.user = user;
      const userRole = user.role;
      state.isAdmin = (userRole === Role.Admin);
      state.isPowerUser = (userRole === Role.PowerUser);
      state.isHardwareApprover = (userRole === Role.Admin) || (userRole === Role.PowerUser) || user.hardwareApprover;
      state.isFigureAdmin =
        state.isAdmin ||
        state.isPowerUser ||
        user.figureAdmin?.length > 0 ||
        user.projectAdmin?.length > 0;
      state.isProjectAdmin =
        state.isAdmin ||
        state.isPowerUser ||
        user.projectAdmin?.length > 0;
      if (this.state.datadogEnabled) {
        datadogRum.setUser({
          id: user.id,
          name: user.fullName,
          email: user.email,
          sapId: user.sapId,
        });
      }
    },
    setFavorites(state, favorites) {
      state.user.favorites = favorites;
    },
    setEditingUser(state, user) {
      if (user.projectEditor == null) {
        user.projectEditor = [];
      }
      if (user.projectAdmin == null) {
        user.projectAdmin = [];
      }
      if (user.figureEditor == null) {
        user.figureEditor = [];
      }
      if (user.figureAdmin == null) {
        user.figureAdmin = [];
      }
      state.editingUser = user;
    }
  },

  getters: {
    getInitials: (state) => () => {
      const userName = state.user?.fullName || state.user.fullName;
      return (
        userName
            ?.split(' ')
            .map((name) => name[0])
            .join('')
            .slice(0, 2) || ''
      );
    }
  }
};
