import Vue from 'vue';
import api from '../../api';
import { IHeader, IFieldTooltip, getEnumOptionText } from '@animtools/rdx-common';
import type {
  FigureTypes,
  SearchFieldByType,
  SearchFieldByTypeResults,
  SearchFieldsByType,
} from './figures.interface';

function getFieldTooltip(state, field: IHeader, type: FigureTypes) {
    //This is wonky, but our "field.parent" values are not plural, and our "FigureTypes" are.
    const parsedType = type === "actuators" ? "actuator" : "function";
    return (
      state.fieldTooltips.find(
        (fieldTooltip) =>
          fieldTooltip.fieldName === field.value &&
          fieldTooltip.itemType?.toLocaleLowerCase() === parsedType &&
          fieldTooltip.parentItemType?.toLocaleLowerCase() === field.parent,
      ) ?? null
    );
  }

export const fieldsModule = {
  namespaced: true,

  state: {
    fieldTooltips: [] as IFieldTooltip[],

    searchFieldsByType: {
      actuators: {},
      functions: {},
    } as SearchFieldsByType,
  },

  getters: {
    getFieldTooltip: (state) => (field: IHeader, type: FigureTypes) => {
      return getFieldTooltip(state, field, type);
    },

    getSearchFieldByType:
      (state) =>
      (id: string, type: FigureTypes): SearchFieldByType => {
        return (
          state.searchFieldsByType[type][id] ??
          ({
            text: null,
            pagination: {
              current: null,
              total: null,
            },
            results: new WeakMap(),
            resultsPosition: new WeakMap(),
          } as SearchFieldByType)
        );
      },

    getFieldValue: (_state, getters, _rootState, rootGetters) => (item, field: IHeader, readOnly: boolean) => {
      // 1. Find the item we need to pull the value from
      if (field.parent) {
        item = item[field.parent];
      }
      if (field.parentCalculation) {
        item = field.parentCalculation(item);
      }
      if (item == null) {
        return 'No value';
      }

      // 2. Get the value from item or calculation
      let value = item[field.value];
      if ((!getters.isEditableField(field) || readOnly) && field.value === 'isLinear') {
        return value ? 'LIN' : 'ROT';
      }

      if (field.type === 'number') {
        value = rootGetters['units/getSelectedUnitFieldValue'](item, field) ?? 'No value';
      } else {
        if (field.calculation) {
          value = field.calculation(item);
        }
        if (field.type === 'enum' && (!getters.isEditableField(field) || readOnly)) {
          return getEnumOptionText(item, field, value);
        }
      }

      if (value == null) {
        return 'No value';
      }

      return value;
    },
    isEditableField: () => (field: IHeader): boolean => {
      return !field.readonly && !(field.value === 'tbd');
    },
  },

  actions: {
    fieldTooltipsGet({ commit }) {
      return api
        .getFieldTooltips()
        .then((response) => {
          commit('setFieldTooltips', response.data || []);
          return true;
        })
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        });
    },

    setSearchFieldText(
      { commit, getters },
      {
        text,
        id,
        type,
        fields,
      }: {
        text: string | null;
        id: string;
        type: FigureTypes;
        fields: IHeader[];
      },
    ) {
      const searchField: SearchFieldByType = getters.getSearchFieldByType(
        id,
        type,
      );
      searchField.text = text;

      commit('setSearchFieldByType', { searchField, id, type, fields });
      commit('searchFieldByType', { id, type, fields });
    },

    unsetSearchFieldText(
      { commit, getters },
      { id, type }: { id: string; type: FigureTypes },
    ) {
      const searchField: SearchFieldByType = getters.getSearchFieldByType(
        id,
        type,
      );
      searchField.text = null;

      commit('setSearchFieldByType', { searchField, id, type });
    },

    previousSearchField(
      { commit, getters },
      { id, type }: { id: string; type: FigureTypes },
    ) {
      const searchField: SearchFieldByType = getters.getSearchFieldByType(
        id,
        type,
      );

      const oldCurrent = searchField.pagination.current;
      if (searchField.pagination.current === 1) {
        searchField.pagination.current = searchField.pagination.total;
      } else {
        searchField.pagination.current -= 1;
      }

      if (oldCurrent === searchField.pagination.current) {
        return;
      }

      commit('setSearchFieldByType', { searchField, id, type });
    },

    nextSearchField(
      { commit, getters },
      { id, type }: { id: string; type: FigureTypes },
    ) {
      const searchField: SearchFieldByType = getters.getSearchFieldByType(
        id,
        type,
      );

      const oldCurrent = searchField.pagination.current;
      if (searchField.pagination.current < searchField.pagination.total) {
        searchField.pagination.current += 1;
      } else {
        searchField.pagination.current = 1;
      }

      if (oldCurrent === searchField.pagination.current) {
        return;
      }

      commit('setSearchFieldByType', { searchField, id, type });
    },
  },

  mutations: {
    setFieldTooltips(state, value) {
      state.fieldTooltips = value;
    },

    setSearchFieldByType(
      state,
      {
        searchField,
        id,
        type,
      }: {
        searchField: SearchFieldByType;
        id: string;
        type: FigureTypes;
      },
    ) {
      if (searchField.text === null) {
        Vue.delete(state.searchFieldsByType[type], id);
        return;
      }

      Vue.set(state.searchFieldsByType[type], id, searchField);
    },

    searchFieldByType(
      state,
      {
        id,
        type,
        fields,
      }: { id: string; type: FigureTypes; fields: IHeader[] },
    ) {
      const searchField: SearchFieldByType = state.searchFieldsByType[type][id];

      if (!searchField) {
        return;
      }

      const results: SearchFieldByTypeResults = new WeakMap();
      const resultsPosition = new WeakMap<IHeader, number>();
      const searchTextLength = searchField.text.length;
      const searchText = searchField.text.toLocaleLowerCase();
      let totalResults = 0;
      for (const field of fields) {
        const textIndex = field.text.toLocaleLowerCase().indexOf(searchText);
        const fieldTooltip = getFieldTooltip(state, field, type);
        const tooltipIndex =
          fieldTooltip?.tooltip?.toLocaleLowerCase().indexOf(searchText) ?? -1;
        if (textIndex === -1 && tooltipIndex === -1) {
          continue;
        }

        if (textIndex != -1) {
          const beforeText = field.text.slice(0, textIndex);
          const text = field.text.slice(
            textIndex,
            searchTextLength + textIndex,
          );
          const afterText = field.text.slice(searchTextLength + textIndex);
          results.set(field, {
            beforeText,
            text,
            afterText,
          });
        } else {
          results.set(field, {
            beforeText: '',
            text: field.text,
            afterText: '',
          });
        }

        totalResults++;

        resultsPosition.set(field, totalResults);
      }

      searchField.results = results;
      searchField.resultsPosition = resultsPosition;

      if (totalResults) {
        searchField.pagination.current = 1;
        searchField.pagination.total = totalResults;
      } else {
        searchField.pagination.current = null;
        searchField.pagination.total = null;
      }

      Vue.set(state.searchFieldsByType[type], id, searchField);
    },
  },
};
