export enum ColumnMenuType {
  Filter = 'Filter',
  ColumnValue = 'ColumnValue',
}

export enum ConfirmationStates {
  Datapicker = 'datapicker',
  Reroute = 'reroute',
  Commit = 'commit',
  Undo = 'undo',
}

export const NO_VALUE_FIELD = 'noValue';
export const ADD_COLUMN_FIELD = 'addColumn';
export const DEFAULT_HEADER_HEIGHT = 32;
