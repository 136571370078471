import api from '../../api';

export const versionsModule = {
  namespaced: true,

  state: {
    versions: [],
    subgroups: [],
    publishLabels: [],
  },

  actions: {
    async getSubgroups({commit}, {figureUuid, designPhase}) {
      try {
        const response = await api.getSubgroups(figureUuid, designPhase);
        commit('setSubgroups', response.data);
      } catch (error) {
        console.error(error);
        commit('pushError', error);
      }
    },

    async getPublishLabels({commit}, {figureUuid, designPhase}) {
      try {
        const response = await api.getPublishLabels(figureUuid, designPhase);
        commit('setPublishLabels', response.data);
      } catch (error) {
        console.error(error);
        commit('pushError', error);
      }
    },

    async getVersions({commit}, {figureId, subgroup, fileCategory, name, designPhase}) {
      try {
        const response = await api.getVersions(figureId, subgroup, fileCategory, name, designPhase);
        commit('setVersions', response.data);
      } catch (error) {
        console.error(error);
        commit('pushError', error);
      }
    },

    getVersion({commit}, {versionId}) {
      return api.getVersion(versionId).catch((error) => {
        console.log(error);
        commit('pushError', error);
      });
    },

    addVersion({commit}, {version}) {
      return api
          .addVersion(version)
          .then((response) => {
            commit('addVersion', response.data);
            return response;
          })
          .catch((error) => {
            console.log(error);
            commit('pushError', error);
          });
    },

    editVersion({commit}, {versionId, version}) {
      return api
          .editVersion(versionId, version)
          .then((response) => {
            commit('editVersion', response.data);
            return response;
          })
          .catch((error) => {
            console.log(error);
            commit('pushError', error);
          });
    },
  },

  mutations: {
    setSubgroups(state, array) {
      state.subgroups = array;
    },

    setPublishLabels(state, array) {
      state.publishLabels = array;
    },

    setVersions(state, array) {
      state.versions = array;
    },

    addVersion(state, version) {
      state.versions.push(version);
    },

    editVersion(state, version) {
      const idx = state.versions.findIndex((e) => e.id === version.id);
      state.versions[idx] = version;
    },
  },
};
