import { FILE_CATEGORIES, truncateFilename } from '@animtools/rdx-common';
import * as _ from 'lodash';

export default {
  data: () => ({
    maxFilenameLength: 41,
  }),
  methods: {
    versionColor(status) {
      let color = 'older--version';
      switch (status) {
        case 'current':
          color = 'success';
          break;
        case 'irregular':
          color = 'warning';
          break;
      }
      return color;
    },
    groupBy(items, key) {
      return items.reduce(
          (result, item) => ({
            ...result,
            [item[key]]: [...(result[item[key]] || []), item],
          }),
          {},
      );
    },

    getFigureFiles(figureFiles) {
      const figureObj = {};
      let figureData = {};

      FILE_CATEGORIES.forEach((e) => {
        figureObj[e.value] = _.cloneDeep(e);
      });
      figureData = figureObj;

      this.removeSuffix(figureFiles);

      this.buildFigureData(Object.keys(figureData), figureFiles, figureData);

      return figureData;
    },

    getKeyByValue(obj, val) {
      return Object.keys(obj).find((key) => obj[key] === val);
    },

    getDirectoriesWithFiles(figureData) {
      const dir = [];
      for (const directory of Object.keys(figureData)) {
        if (figureData[directory].files?.length) {
          dir.push(directory);
        }
      }
      return dir;
    },

    removeSuffix(figureFiles) {
      // remove p000 suffix for sorting
      figureFiles.forEach((f) => {
        f.sortName = f.originalName
            ?.replace(/_p[0-9]{3}(\.[^.]+)$/, '$1')
            ?.replace(/_pdf(\.[^.]+)$/, '$1');

        // Treat files as unique objects if they have the same name
        // but are in different subgroups.
        if (f.versionEntity?.subgroup) {
          f.sortName = f.versionEntity?.subgroup + f.sortName;
        }
      });
    },

    buildFigureData(directories, figureFiles, figureData) {
      const sortedFiles = _.orderBy(
          figureFiles,
          ['sortName', 'publishedAt'],
          ['asc', 'desc'],
      );

      for (const file of sortedFiles) {
        for (const dir of directories) {
          this.processDirectory(dir, file, figureData);
        }
      }
    },

    processDirectory(dir, file, figureData) {
      const storagePathSplit = file.storagePath.split('/');
      if (dir === 'rdx') {
        const geometryJSON = `${dir}/Geometry/JSON`;
        const geometrySTL = `${dir}/Geometry/STL`;
        if (file.storagePath.includes(geometryJSON)) {
          // Checking if file is already in the files array
          if (!figureData[dir].files?.some((el) => el.id === file.id)) {
            const metadata = this.getMetadata(file);
            figureData[dir].Geometry.JSON.files.push(metadata);
          }
        } else if (file.storagePath.includes(geometrySTL)) {
          if (!figureData[dir].files?.some((el) => el.id === file.id)) {
            const metadata = this.getMetadata(file);
            figureData[dir].Geometry.STL.files.push(metadata);
          }
        }
      }
      if (
        dir === 'animation' &&
        ['animation-csv', 'movie-file'].includes(storagePathSplit[2])
      ) {
        const metadata = this.getMetadata(file);
        figureData['animation'].files.push(metadata);
      } else if (storagePathSplit[2] === dir) {
        if (!figureData[dir].files.some((el) => el.id === file.id)) {
          if (dir === 'rdx') {
            file.sortName = file.sortName.replace(/(-pym|-rdx)(\.[^.]+)$/, '');
          }
          const metadata = this.getMetadata(file);
          figureData[dir].files.push(metadata);
        }
      }
    },

    getMetadata(file) {
      return {
        id: file['id'],
        name: file['originalName'],
        pathCache: file['pathCache'],
        sortName: file['sortName'],
        upstreamRigs: file['upstreamRigs'],
        storagePath: file['storagePath'],
        jsonSource: file['jsonSource'],
        mimeType: file['mimeType'],
        version: file['version'],
        versionEntity: file['versionEntity'],
        publishedAt: new Date(file['publishedAt']).toUTCString(),
        publishedBy: file['publishedBy'],
        publishedByName: file['publishedByName'],
      };
    },

    updateFilesMetadata(files) {
      this.removeSuffix(files);
      files.forEach((f) => {
        if (f.storagePath && f.storagePath.substr('/rdx/') !== -1) {
          f.sortName = f.sortName.replace(/(-pym|-rdx)(\.[^.]+)$/, '');
        }
      });

      return files;
    },

    getName(publishedBy, user) {
      if (publishedBy && user && user.sapId) {
        return publishedBy.toLowerCase() === user.sapId.toLowerCase() ?
          user.fullName :
          publishedBy;
      } else {
        return publishedBy;
      }
    },

    isEmptyObj(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },

    getFileTypes(figureFiles) {
      const fileTypes = [];
      figureFiles.forEach((file) => {
        const fileType = this.extractFileType(file['name']);
        if (!fileTypes.includes(fileType)) {
          fileTypes.push(fileType);
        }
      });
      return fileTypes;
    },

    extractFileType(filename) {
      const re = /(?:\.([^.]+))?$/;
      return re.exec(filename)[1];
    },

    truncateFilename(filename) {
      return truncateFilename(filename, this.maxFilenameLength);
    },
  },
};
