import Vue from 'vue';
import api from '../../api';
import { IActuator, IFunction, IHeader } from '@animtools/rdx-common';

export const trackingsModule = {
  namespaced: true,

  state: {
    trackings: [],
    highlight: false,
    highlightedItems: {},
    excludeOptionalFields: false,
    trackingDialog: {
      isOpen: false,
      tracking: null,
      item: null,
      field: null,
    },
  },

  getters: {
    trackingHighlightField: (state) => ({ itemUuid, itemKey, defaultOwner, defaultMilestone, defaultOptionalMilestone }) => {
      // If the user has selected to highlight dow and milestones, then the tracking must match both filters
      // Otherwise, it only needs to match one of them
      if (state.highlightedItems[itemUuid]) {
 
        const dowIsSelected = state.highlightedItems[itemUuid]['dow'].length > 0
        const milestoneIsSelected = state.highlightedItems[itemUuid]['milestone'].length > 0
        if (dowIsSelected || milestoneIsSelected) {
          let containsTrackedDowColumns = true
          let containsTrackedMilestoneColumns = true

          const trackingList = state.trackings;

          if (dowIsSelected) {
            
            containsTrackedDowColumns = trackingList.some((tracking) => itemKey === tracking.columnName && state.highlightedItems[itemUuid]['dow'].includes(tracking.owner))

            if (!containsTrackedDowColumns) {
              // Check if the default should be used instead, when the tracking is not in the list and defalt values match
              containsTrackedDowColumns = !trackingList.some((tracking) => tracking.columnName === itemKey) && state.highlightedItems[itemUuid]['dow'].includes(defaultOwner)
            }

          }

          if (milestoneIsSelected) {
            containsTrackedMilestoneColumns = trackingList.some((tracking) => itemKey === tracking.columnName && state.highlightedItems[itemUuid]['milestone'].includes(tracking.milestone))

            if (!containsTrackedMilestoneColumns) {
              // Check if the default should be used instead, when the tracking is not in the list and defalt values match
              containsTrackedMilestoneColumns =  !trackingList.some((tracking) => tracking.columnName === itemKey) && state.highlightedItems[itemUuid]['milestone'].includes(defaultMilestone)
            }
          }

          // Do not highlight if optional
          if (state.excludeOptionalFields) {
            const listContainsOptional = trackingList.some((tracking) => tracking.columnName === itemKey && tracking.optional)
            const defaultIsOptional = !trackingList.some((tracking) => tracking.columnName === itemKey) && defaultOptionalMilestone

            if (listContainsOptional || defaultIsOptional) {
              return false
            }
          }

          return containsTrackedDowColumns && containsTrackedMilestoneColumns
        }
      }
      return false
    },

    getTracking: (state) => (field) => {
      return (
        state.trackings.find((tracking) => tracking.columnName === field) ??
        null
      );
    },
    getHighlightedElementsByType: (state) => (item: string, type: string) => {
      if (item in state.highlightedItems) {
        return state.highlightedItems[item][type]
      }
      return [];
    }
  },

  actions: {
    trackingsGet({ commit }, figureUuid) {
      return api
        .getTrackingsForFigure(figureUuid)
        .then((response) => {
          commit('setTrackings', response.data);
          return true;
        })
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        });
    },

    trackingsPost({ commit }, changes) {
      return api
        .createTrackings({ data: changes })
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        });
    },

    updateTrackingsForProject({ commit }, { change, projectId }) {
      return api
        .updateAllProjectsTrackings({ data: change }, projectId)
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        });
    },

    setTrackingsDialog({ commit }, { tracking, item, field, isOpen }) {
      commit('setDialogTracking', tracking);
      commit('setDialogItem', item);
      commit('setDialogField', field);
      commit('setDialogIsOpen', isOpen);
    },
  },

  mutations: {
    setTrackings(state, value) {
      state.trackings = value;
    },
    addHighlightedItem(state, payload) {
      if (state.highlightedItems[payload.id] === undefined) {
        Vue.set(state.highlightedItems, payload.id, {dow: [], milestone: []});
      }
      state.highlightedItems[payload.id][payload.type].push(payload.value)
    },
    deleteHighlightedItem(state, payload) {
      const index = state.highlightedItems[payload.id][payload.type].indexOf(payload.value);
      if (index > -1) { 
        state.highlightedItems[payload.id][payload.type].splice(index, 1);
      }
    },
    setExcludeOptionalFields(state, newValue) {
      state.excludeOptionalFields = newValue;
    },
    clearAllHighlights(state, payload) {
      if (state.highlightedItems[payload.id]) {
        state.highlightedItems[payload.id][payload.type] = [];
      }
    },
    setDialogTracking(state, newValue) {
      Vue.set(state.trackingDialog, 'tracking', newValue);
    },
    setDialogItem(state, item: IActuator | IFunction) {
      Vue.set(state.trackingDialog, 'item', item);
    },
    setDialogField(state, field: IHeader) {
      Vue.set(state.trackingDialog, 'field', field);
    },
    setDialogIsOpen(state, newValue: boolean) {
      Vue.set(state.trackingDialog, 'isOpen', newValue);
    },
  },
};
