import { DEFAULT_HEADER_FILES, IHeader, getUnitsMap } from '@animtools/rdx-common';

import * as overviewHeaders from './headers/figure-overview';
import * as miscHeaders from './headers/misc';

const headerFiles = [
  ...DEFAULT_HEADER_FILES,
  ...Object.values(overviewHeaders),
  ...Object.values(miscHeaders),
] as IHeader[][][];

export const unitsMap = getUnitsMap({headerFiles});
