import Vue from 'vue';
import VueSocketIOExt from 'vue-socket.io-extended';
import * as io from 'socket.io-client';
import {datadogRum} from '@datadog/browser-rum';
import {LicenseManager} from  'ag-grid-enterprise';

LicenseManager.setLicenseKey('%AG_GRID_LICENSE_KEY%');

import App from './App.vue';
import vuetify from './plugins/vuetify';
import {setupInterceptors} from './httpInterceptors';
import router from './router';
import store from './store/store';

Vue.config.productionTip = true;

Vue.filter('join', function(value) {
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  return value;
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    if (to.meta?.title) {
      document.title = 'RDX Web - ' + to.meta.title;
    } else {
      document.title = 'RDX Web';
    }
  });
});

const url =
  location.protocol +
  '//' +
  location.hostname +
  (location.port ? ':' + location.port : '');
const socket = io.connect(url, {transports: ['websocket']});

Vue.use(VueSocketIOExt, socket, {store});

// Feature toggle defined in Vault to enable Datadog
const DATADOG_ENABLED = '%DATADOG_ENABLED%';
const datadogEnabled = DATADOG_ENABLED.toLowerCase() === 'true';
store.state.datadogEnabled = datadogEnabled;

if (datadogEnabled) {
  datadogRum.init({
    applicationId: '%DATADOG_APPLICATION_ID%',
    clientToken: '%DATADOG_CLIENT_TOKEN%',
    site: 'datadoghq.com',
    service: 'rdx-web',
    env: '%ENVIRONMENT%',
    // Defined by the CI pipeline at build time.
    // eslint-disable-next-line no-undef
    version: REVISION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask',
  });
}

new Vue({
  vuetify,
  router,
  store,
  created() {
    if (datadogEnabled) {
      datadogRum.startSessionReplayRecording();
    }
    setupInterceptors();
  },
  render: (h) => h(App),
}).$mount('#app');
