<template>
  <v-fade-transition>
    <div v-if="isLoadingContent" class="loading-background">
      <img
        
        class="loading"
        :src="loadingImg"
        alt="Loading content"
      />
    </div>
  </v-fade-transition>
</template>

<script lang="ts">
import { mapState } from 'vuex';

export default {
  name: 'LoadingContent',
  computed: {
    ...mapState(['isLoadingContent']),
    loadingImg() {
      return `${require('@/assets/figure/loading-content.svg')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
$height: 211px;
$width: 155px;

.loading-background {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #FFFFFF66;
  z-index: 100;
}

.loading {
  position: fixed;
  top: calc(50% - #{$height} / 2);
  left: calc(50% - #{$width} / 2);
  width: $width;
  height: $height;
  pointer-events: none;
  z-index: 9999;
}
</style>
