import Vue from 'vue';

export const socketModule = {
  namespaced: true,

  state: {
    subscriptions: [], // Socket.io: Keep a list of subscriptions, so we can re-sub on reconnect
  },


  actions: {
    // This is will be called by Vue.socketio on reconnect
    socket_reconnect({state}) {
      for (const {type, id} of state.subscriptions) {
        this._vm.$socket.client.emit(`${type}_reconnect`, id);
      }
    },

    subscribe({commit, state}, {type, id}) {
      const isAlreadySubscribed = state.subscriptions.some((i) => type === i.type && id === i.id);
      if (isAlreadySubscribed) {
        return true;
      }
      if (type == "figure") {
        commit('figures/setFiguresLoading', true, {root: true});
        commit('figures/setFigureLoading', true, {root: true});
        commit('figures/setTrackingsLoading', true, {root: true});
      }

      return new Promise((resolve, reject) => {
        this._vm.$socket.client.emit(`${type}_subscribe`, id, (response) => {
          if (response.status == 'error') {
            let message = response.err?.message ? `${response.err.message}` : `${response.message}`;
            const status = response.err?.status ? `${response.err.status}` : `${response.status}`;
            if (response.err?.message?.statusCode) {
              message = `${response.err.message.statusCode} - ${response.err.message.error}`;
            }
            const statusCode = response.err?.statusCode ? response.err.statusCode : response.statusCode;
            commit('figures/setFigure', {error: true, message, status, statusCode, data: {}, loading: false}, 
              {root: true});
            reject(response.err);
          } else if (response.status == 'success') {
            commit('addSubscription', {type, id} );
            resolve(response);
          } else {
            console.error('Unknown response', response);
          }
        });
      });
    },

    unsubscribe({commit, state}, {type, id}) {
      const subIndex = state.subscriptions.findIndex((i) =>
        ( type == i.type && id == i.id ),
      );
      if (subIndex == -1) {
        console.log('No subscription found', type, id);
        return;
      }

      this._vm.$socket.client.emit(`${type}_unsubscribe`, id, (response) => {
        if (response.status == 'error') {
          console.error('Failed to unsubscribe');
        }
        commit('removeSubscription', subIndex );
      });
    },

    unsubscribeAllOfType({commit, state}, {type}) {
      const subscriptions = state.subscriptions.filter((i) => type == i.type);
      if (subscriptions.length == 0) {
        return;
      }

      for (const sub of subscriptions) {
        this._vm.$socket.client.emit(`${sub.type}_unsubscribe`, sub.id, (response) => {
          if (response.status == 'error') {
            console.error('Failed to unsubscribe');
          }
          commit('removeSubscription', state.subscriptions.indexOf(sub));
        });
      }
    },

  },


  mutations: {
    // All mutations prefaced with SOCKET_
    // are called directly by socket.io VueSocketIOExt
    // These callbacks are defined in the appropriate Vuex module
    addSubscription(state, subscription) {
      state.subscriptions.push(subscription);
    },

    removeSubscription(state, subIndex) {
      Vue.delete(state.subscriptions, subIndex);
    },
  },
};
