import Vue from 'vue';
import {
  ACTUATOR_DANCER_HEADERS,
  ACTUATOR_ALL_HEADERS,
  ACTUATOR_FORMULAS
} from '@animtools/rdx-common';

// Actuators and Functions are linked using many to many table in
// SQL database. When Actuator is changed, ensure that any of linked
// functions are also changed
export function updateFunctionsOnActuatorChange(
    changedActuator,
    stateFunctions,
) {
  // get uuids of all functions this actuator links to
  const functions = changedActuator.functions || [];
  const linkedFunctionUuids = functions.map((f) => f.uuid);

  // Go through all functions and ensure there is a link back to this actuator.
  for (const function_ of stateFunctions) {
    // Does state function link to actuator we changed
    const linkIndex = function_.actuators.findIndex(
        (a) => a.uuid == changedActuator.uuid,
    );
    // Does actuator we changed link to state function
    const shouldLink = linkedFunctionUuids.includes(function_.uuid);

    // Function is listed in changed actuator,
    // but it doesn't have actuator listed in its links
    if (shouldLink && linkIndex == -1) {
      function_.actuators.push({
        uuid: changedActuator.uuid,
        name: changedActuator.name,
      });
    // Function is not listed in changed actuator,
    // but it has actuator listed in its links
    } else if (!shouldLink && linkIndex != -1) {
      Vue.delete(function_.actuators, linkIndex);
    }
  }
}

// Actuators and Functions are linked using many to many table in
// SQL database. When Function is changed, ensure that any of linked
// Actuators are also changed
export function updateActuatorsOnFunctionChange(
    changedFunction,
    stateActuators,
) {
  // get uuids of all actuators uuids this function links to
  const actuators = changedFunction.actuators || [];
  const linkedActuatorUuids = actuators.map((a) => a.uuid);

  // Go through all actuators and ensure there is a link back to this function.
  for (const actuator of stateActuators) {
    // Does state actuator link to function we changed
    const linkIndex = actuator.functions.findIndex(
        (f) => f.uuid == changedFunction.uuid,
    );
    // Does function we changed link to state actuator
    const shouldLink = linkedActuatorUuids.includes(actuator.uuid);

    // Function is listed in changed actuator,
    // but it doesn't have actuator listed in its links
    if (shouldLink && linkIndex == -1) {
      console.log('Adding', actuator.functions, changedFunction.uuid);
      actuator.functions.push({
        uuid: changedFunction.uuid,
        name: changedFunction.name,
      });
    // Actuator is not listed in changed actuator,
    // but it has actuator listed in its links
    } else if (!shouldLink && linkIndex != -1) {
      console.log('Delete', actuator.functions, linkIndex);
      Vue.delete(actuator.functions, linkIndex);
    }
  }
}

/* Baymax ID creation here within Vuex is to allow it to be updated when a
user changes name, number or channel in UI but still allow it to be
filterable and sortable. Argument should be object. */
export const updateBaymaxId = (actuator) => {
  actuator.baymaxId = ACTUATOR_FORMULAS.baymaxId(actuator);
};

export function sanitizeUpdatedActuatorChanges(actuator, changes) {
  const allHeaders = ACTUATOR_ALL_HEADERS.concat(ACTUATOR_DANCER_HEADERS);
  const changedActuator = changes.find((change) => change.type);
  const actuatorType = changedActuator?.type || actuator.type;

  changes.forEach((change) => {
    Object.keys(change).forEach((key) => {
      if (change[key]) {
        const isValid = isFieldValidForActuatorType(allHeaders, actuatorType, key);
        if (!isValid) {
          change[key] = null;
        }
      }
    });
  });
}

export function isFieldValidForActuatorType(allHeaders, type, fieldName) {
  const headerForField = allHeaders.find((header) => header.value === fieldName);
  if (!headerForField) {
    return true;
  }

  return !isHeaderNotAvailable(headerForField, {type});
}

export function isHeaderNotAvailable(header, actuator) {
  return header.available != null && header.available(actuator) === false;
}
