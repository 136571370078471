import api from '../../api';


export const reviewsModule = {
  namespaced: true,

  state: {
    reviews: [],
  },


  actions: {
    getReviews({commit}) {
      api.getReviews()
          .then((response) => {
            commit('setReviews', response.data);
          }).catch((error) => {
            commit('pushError', error, {root: true});
          });
    },

    createReview({commit, dispatch}, review) {
      api.createReview(review)
          .then((/* response*/) => {
            dispatch('getReviews');
          }).catch((error) => {
            commit('pushError', error, {root: true});
          });
    },

    approveReview({commit, dispatch}, review) {
      api.approveReview(review)
          .then((/* response*/) => {
            dispatch('getReviews');
          }).catch((error) => {
            commit('pushError', error, {root: true});
          });
    },

    denyReview({commit, dispatch}, {review, reason}) {
      api.denyReview(review, reason)
          .then((/* response*/) => {
            dispatch('getReviews');
          }).catch((error) => {
            commit('pushError', error, {root: true});
          });
    },

    cancelReview({commit, dispatch}, review) {
      api.cancelReview(review)
          .then((/* response*/) => {
            dispatch('getReviews');
          }).catch((error) => {
            commit('pushError', error, {root: true});
          });
    },
  },


  mutations: {
    setReviews(state, reviews) {
      state.reviews = reviews;
    },
  },
};
