import api from '../../api';

export const apiModule = {
  namespaced: true,

  state: {
    tokens: [],
    newToken: null,
  },


  actions: {
    getTokens({commit}) {
      api.getTokens()
          .then((response) => {
            commit('setTokens', response.data);
          }).catch((error) => {
            commit('pushError', error);
          });
    },

    getAllTokens({commit}) {
      api.getAllTokens()
          .then((response) => {
            commit('setTokens', response.data);
          }).catch((error) => {
            commit('pushError', error);
          });
    },

    createToken({commit, dispatch}, token) {
      api.createToken(token)
          .then((response) => {
            commit('setNewToken', response.data);
            dispatch('getTokens');
          }).catch((error) => {
            commit('pushError', error);
          });
    },

    deleteToken({commit, dispatch}, token) {
      api.deleteToken(token)
          .then((/* response*/) => {
            dispatch('getTokens');
          }).catch((error) => {
            commit('pushError', error);
          });
    },

    async fetchTableSettings({commit}) {
      return api.getTableSettings()
          .then((response) => {
            commit('setTableSettings', response.data);
          }).catch((error) => {
            console.log('error fetching settings', error);
            commit('setTableSettings', {});
          });
    },

    async setTableSettings({commit}, data) {
      return api.saveTableSettings(data)
          .then((response) => {
            commit('setTableSettings', response.data);
          }).catch((error) => {
            console.log('error saving settings', error);
            commit('setTableSettings', data);
          });
    },
  },


  mutations: {
    setTokens(state, tokens) {
      state.tokens = tokens;
    },

    setNewToken(state, password) {
      state.newToken = password;
    },

    setTableSettings(state, data) {
      state.tableSettings = data;
    },
  },
};
