import httpClient from './httpClient';
import {popupWindow} from './util';

export function setupInterceptors() {
  httpClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response?.status === 401) {
          localStorage.setItem('skipAuthReload', 'true');
          popupWindow('/auth/login', 'Login', 599, 599);
        }
        return Promise.reject(error);
      },
  );
}
