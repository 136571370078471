import api from '../../api';
import { TICKETBOOTH_BUG_REPORT_URL } from '../../constants/urls';

export const fileModule = {
  namespaced: true,

  state: {
    file: {},
    metadata: null,
  },

  actions: {
    getFileMetadata({ commit }, { fileId }) {
      return api
        .getFileMetadata(fileId)
        .then(({ data }) => {
          commit('setFileMetadata', data);
          return data;
        })
        .catch((error) => {
          console.log(error);
          commit('pushError', error, { root: true });
          return error;
        });
    },

    getShotgunFilePublishName({ commit }, { metadata }) {
      return api.getShotgunFilePublishName(metadata).catch((error) => {
        console.log(error);
        commit(
          'pushError',
          (error.response?.data.message &&
            error.response?.data.message.replace(/^.+Error: /, '')) ||
            error,
          { root: true },
        );
      });
    },

    putPresignedUrl({ commit }, { file }) {
      return api.putPresignedUrl(file).catch((error) => {
        console.log(error);
        commit('pushError', error, { root: true });
      });
    },

    addFileMetadata({ commit }, { metadata }) {
      return api.addFileMetadata(metadata).catch((error) => {
        console.log(error);
        commit('pushError', error, { root: true });
      });
    },

    editFileMetadata({ commit }, { metadata }) {
      return api.editFileMetadata(metadata).catch((error) => {
        console.log(error);
        commit('pushError', error, { root: true });
      });
    },

    editFileMetadataBulk({ commit }, { filesMetadata }) {
      return api.editFileMetadataBulk(filesMetadata).catch((error) => {
        console.log(error);
        commit('pushError', error, { root: true });
      });
    },

    clearFileMetadata({ commit }) {
      commit('setFileMetadata', null);
      return true;
    },

    removeFileMetadata({ commit }, { fileId }) {
      return api.removeFile(fileId).catch((error) => {
        console.log(error);
        commit('pushError', error, { root: true });
      });
    },

    destroyFile({ commit }, { fileId }) {
      return api.destroyFile(fileId).catch((error) => {
        console.log(error);
        commit('pushError', error, { root: true });
      });
    },

    shotgunFileInitialize({ commit }, { metadata }) {
      return api.shotgunFileInitialize(metadata).catch((error) => {
        console.log(error);
        commit('pushError', error, { root: true });
      });
    },

    downloadFilesInZip({ commit }, { payload }) {
      return api.downloadFilesInZip(payload).catch((error) => {
        console.log(error);
        commit('pushError', error, { root: true });
      });
    },

    async uploadFile({commit}, {file, presignedUrl, fn}) {
      const upload = await api.uploadFile(file, presignedUrl, fn);

      if (upload.error) {
        console.log(upload.error);
        commit('pushError', upload.error, {root: true});
      } else if (upload.response.code === 'ECONNABORTED') {
        console.log('Timeout during file upload.');
        commit('pushError', `Timeout during file upload. If the problem continues, please submit a ticket via <a href="${TICKETBOOTH_BUG_REPORT_URL}" target="_blank">TicketBooth</a>.`, {root: true});
      }

      return upload;
    },
  },

  mutations: {
    setFile(state, file) {
      state.file = file;
    },
    setFileMetadata(state, metadata) {
      state.metadata = metadata;
    },
    editVersionInFile(state, versionId) {
      state.file.versionEntity = versionId;
    },
  },
};
