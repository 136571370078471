import Vue from 'vue';
import api from '../../api';

export const checkoutModule = {
  namespaced: true,

  state: {
    checkouts: [],
    steps: [],
    currentStep: {},
    currentTab: false,
    currentPagItem: 0,
    statusSelectedItem: false,
    comments: {},
    currentComment: {},
    commentsFilterType: 'comment',
    currentChanges: [],
    currentReferences: [],
    checkoutHistory: [],
    checkoutStepsProgress: {},
    hasChanges: false,
    hasChangesInEdit: false,
    triedToReroute: false,
    rerouteUrl: undefined,
    currentUnitsModal: [],
    isLoadingCheckoutStepsProgress: false,
  },

  actions: {
    getCheckoutStepsProgress({ commit }, { figureUuid, type }) {
      commit('setIsLoadingCheckoutStepsProgress', true);
      return api
        .getProgressByType(figureUuid, type)
        .then((res) => {
          const response = res.data;
          commit('setCheckoutStepsProgress', { type, response });
        })
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        })
        .finally(() => {
          commit('setIsLoadingCheckoutStepsProgress', false);
        });
    },
    getCheckouts({ commit }, { figureUuid, type, step, slug }) {
      return api
        .getCheckout(figureUuid, type, step, slug)
        .then((response) => {
          if (type) {
            commit('setCheckouts', [response.data]);
            commit('addCheckout', response.data);
          } else {
            commit('setCheckouts', response.data);
          }
        })
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        });
    },
    getCheckoutCommentsByStep({ commit, state }, { figureUuid, type, step, commentType }) {
      if (commentType) {
        commit('setCommentsFilterType', commentType);
      }
      return api
        .getCheckoutCommentsByStep(figureUuid, type, step, state.commentsFilterType)
        .then(({ data }) => {
          commit('setComments', { type, data });
        })
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        });
    },
    saveCheckoutComment({ commit }, { type, payload }) {
      return api
        .saveCheckoutComment(payload)
        .then(({ data }) => {
          commit('addComment', { type, data });
        })
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        });
    },
    saveCheckoutStepDetail(
      { commit },
      { figureUuid, type, step, slug, payload },
    ) {
      return api
        .saveCheckoutStepDetail(figureUuid, type, step, slug, payload)
        .then((response) => {
          commit('addCheckout', response.data);
        })
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        });
    },
    saveCommit({ commit }, { figureUuid, type, step, payload }) {
      return api
        .saveCommit(figureUuid, type, step, payload)
        .then(() => {
          commit('setCommitedCheckouts', {changes: payload.changes, step: step});
        })
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        });
    },
    getReferences({ commit }, { figureUuid, type, step }) {
      return api
        .getReferences(figureUuid, type, step)
        .then((response) => {
          commit('setReferences', response.data);
        })
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        });
    },
    getCheckoutHistory({ commit }, { figureUuid, type, step, slug }) {
      return api
        .getCheckoutHistory(figureUuid, type, step, slug)
        .then((response) => {
          commit('setHistory', response.data);
        })
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        });
    },
    clearCheckoutHistory({ commit }) {
      commit('setHistory', []);
    },
    distributeSteps({ commit }, { uuid, action }) {
      return api.distributeSteps(uuid, action).catch((error) => {
        console.error(error);
        commit('pushError', error, { root: true });
        throw new Error(error);
      });
    },
  },

  mutations: {
    setCheckoutStepsProgress(state, { type, response }) {
      Vue.set(state.checkoutStepsProgress, `checkout-${type}`, response);
    },
    setCheckouts(state, array) {
      state.checkouts = array;
    },
    addCheckout(state, checkout) {
      state.currentStep = checkout;
    },
    setCurrentTab(state, currentTab) {
      state.currentTab = currentTab;
    },
    setCurrentPagItem(state, currentPagItem) {
      state.currentPagItem = currentPagItem;
    },
    setCurrentSelectedItem(state, currentSelectedItem) {
      state.statusSelectedItem = currentSelectedItem;
    },
    setCurrentUnitsModal(state, currentUnitModal) {
      state.currentUnitsModal = currentUnitModal;
    },
    setComments(state, { type, data }) {
      Vue.set(state.comments, `checkout-${type}`, data);
    },
    addComment(state, { type, data }) {
      const comments = state.comments[type];
      comments.unshift(data);
      Vue.set(state.comments, type, comments);
    },
    setCommitedCheckouts(state, payload) {
      for (const change of payload.changes) {
        if (!state.checkouts[0]) {
          Vue.set(state.checkouts, 0, {});
        }
        if (!state.checkouts[0]?.checkoutStepDetails) {
          Vue.set(state.checkouts[0], 'checkoutStepDetails', []);
        }
        const stepIndex = state.checkouts[0]?.checkoutStepDetails.findIndex(
          (step) => step.slug === change.payload.slug,
        );
        if (stepIndex === -1) {
          // Push a new checkout step detail if one does not already exist 
          change.payload['updatedAt'] = new Date().toISOString()
          state.checkouts[0].checkoutStepDetails.push(change.payload)
        } else {
          // Otherwise, update the existing detail with teh new checkboxState
          Vue.set(state.checkouts[0]?.checkoutStepDetails[stepIndex], 'checkboxState', change.payload.checkboxState);
        }
      }
    },
    setReferences(state, array) {
      state.currentReferences = array;
    },
    setHistory(state, array) {
      state.checkoutHistory = array;
    },
    setHasChanges(state, hasChanges) {
      state.hasChanges = hasChanges;
    },
    setHasChangesInEdit(state, hasChangesInEdit) {
      state.hasChangesInEdit = hasChangesInEdit;
    },
    setTriedToReroute(state, triedToReroute) {
      state.triedToReroute = triedToReroute;
    },
    setReroute(state, rerouteUrl) {
      state.rerouteUrl = rerouteUrl;
    },
    setIsLoadingCheckoutStepsProgress(state, isLoading) {
      state.isLoadingCheckoutStepsProgress = isLoading;
    },
    setCommentsFilterType(state, commentType) {
      state.commentsFilterType = commentType;
    },
    SOCKET_PROGRESS_SET(state, payload) {
      const { step, type, progress } = payload;
      const stepProgress = state.checkoutStepsProgress[`checkout-${type}`];
      stepProgress[step] = progress;
      Vue.set(state.checkoutStepsProgress, `checkout-${type}`, stepProgress);
    },
  },
};
