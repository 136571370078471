import Vue from 'vue';
import Vuex from 'vuex';

import { apiModule } from './modules/api';
import { assetsModule } from './modules/assets';
import { figuresModule } from './modules/figures';
import { hardwareModule } from './modules/hardware';
import { reviewsModule } from './modules/reviews';
import { socketModule } from './modules/socket';
import { usersModule } from './modules/users';
import { chipsModule } from './modules/chips';
import { versionsModule } from './modules/versions';
import { fileModule } from './modules/file';
import { globalFileDownloadModule } from './modules/globalFileDownload';
import { checkoutModule } from './modules/checkout';
import { safetyWarningModule } from './modules/safetyWarning';
import { astrolabeModule } from './modules/astrolabe';
import { unitsModule } from './modules/units';
import { actuatorsModule } from './modules/actuators';
import { trackingsModule } from './modules/trackings';
import { functionsModule } from './modules/functions';
import { fieldsModule } from './modules/fields';
import { datapickerModule } from './modules/datapicker'
Vue.use(Vuex);

const store = new Vuex.Store({
  strict: false,

  modules: {
    api: apiModule,
    assets: assetsModule,
    figures: figuresModule,
    actuators: actuatorsModule,
    trackings: trackingsModule,
    hardware: hardwareModule,
    reviews: reviewsModule,
    socket: socketModule,
    users: usersModule,
    chips: chipsModule,
    versions: versionsModule,
    file: fileModule,
    globalFileDownload: globalFileDownloadModule,
    checkout: checkoutModule,
    safetyWarning: safetyWarningModule,
    astrolabe: astrolabeModule,
    units: unitsModule,
    functions: functionsModule,
    fields: fieldsModule,
    datapicker: datapickerModule,
  },

  state: {
    alert: false,
    isLoadingContent: false,
    errorStack: [],

    dialog: false,
    dialogId: 0,

    lastLogin: '',

    datadogEnabled: false,
  },

  actions: {
    dialogCancel({commit}) {
      commit('setDialog', false);
    },
  },

  mutations: {
    setAlert(state, value) {
      state.alert = value;
    },

    pushError(state, error) {
      state.alert = true;
      state.errorStack.push(error);
    },

    setDialog(state, dialog) {
      if (dialog == true) {
        state.dialogId++;
      }
      state.dialog = dialog;
    },

    setLastLogin(state, value) {
      state.lastLogin = value;
    },

    setIsLoadingContent(state, isLoading) {
      state.isLoadingContent = isLoading;
    },
  },
});

export default store;
