import Vue from 'vue';
import {cloneDeep} from 'lodash';
import {IHeader} from '@animtools/rdx-common';
import api from '../../api';
import {ColumnMenuType, DEFAULT_HEADER_HEIGHT, NO_VALUE_FIELD} from '../../constants/datapicker';
import { FigureTypes } from './figures.interface';

export const datapickerModule = {
  namespaced: true,

  state: {
    templates: [],
    selectedTemplate: null,
    originalTemplate: null,
    columnMenuType: null as ColumnMenuType,
    filterText: {},
    focusedCell: {
      rowId: null,
      colId: null,
    },
    editingCell: {
      rowId: null,
      colId: null,
    },
    isEditingTemplate: false,
    isTemplateChanged: false,
    sortedColumnId: '',
    selectedDataType: 'functions' as FigureTypes,
    confirmedDataType: 'functions' as FigureTypes,
    datapickerDialog: {
      isOpen: false,
    },
    headerHeight: DEFAULT_HEADER_HEIGHT,
    columnWidthUpdated: 0,
  },

  getters: {
    getTemplatesByDataType: (state) => (dataType) => {
      return state.templates.filter((template) => template.dataType === dataType);
    },
    getAllTemplates: (state) => {
      return state.templates
    },
  },

  actions: {
    async templatesGet({ commit }) {
      try {
        const response = await api.getDatapickerTemplates();
        commit('setDatapickerTemplates', response.data || {});
        return true;
      } catch (error) {
        console.error(error);
        commit('pushError', error, { root: true });
      }
    },
    async templateDelete({ commit }, templateUuid) {
      try {
        await api.deleteDatapickerTemplates(templateUuid);
        commit('deleteDatapickerTemplates', templateUuid);
      } catch (error) {
        console.error(error);
        commit('pushError', error, { root: true });
      }
    },
    async templateCreate({ commit }, payload) {
      try {
        const response = await api.createDatapickerTemplates(payload);
        commit('addTemplate', response.data || {});
        return response.data;
      } catch (error) {
        console.error(error);
        commit('pushError', error, { root: true });
      }
    },
    async templateUpdate({ commit }, template) {
      try {
        const response = await api.updateDatapickerTemplates(template.id, template);
        commit('updateTemplate', response.data || {});
      } catch (error) {
        console.error(error);
        commit('pushError', error, { root: true });
      }
    },
    setDatapickerDialogOpen({ commit, state }, isOpen) {
      if (!isOpen) {
        state.headerHeight = DEFAULT_HEADER_HEIGHT;
      }
      commit('setDataPickerDialogIsOpen', isOpen);
    },
    async favoriteTemplate({ commit }, templateUuid) {
      try {
        await api.favoriteDatapickerTemplate(templateUuid);
        commit('favoriteTemplate', {templateId: templateUuid, isFavorite: true});
      } catch (error) {
        console.error(error);
        commit('pushError', error, { root: true });
      }
    },
    async unFavoriteTemplate({ commit }, templateUuid) {
      try {
        await api.unFavoriteDatapickerTemplate(templateUuid);
        commit('favoriteTemplate', {templateId: templateUuid, isFavorite: false});
      } catch (error) {
        console.error(error);
        commit('pushError', error, { root: true });
      }
    }
  },

  mutations: {
    addTemplate(state, template) {
      template.uiId = template.id;
      state.templates.push(template)
    },
    setDatapickerTemplates(state, value) {
      state.templates = value;
    },
    setSelectedDataType(state, value) {
      state.selectedDataType  = value;
    },
    setConfirmedDataType(state, value) {
      state.confirmedDataType  = value;
    },
    deleteDatapickerTemplates(state, templateUuid) {
      const index = state.templates.findIndex((i) => i.id === templateUuid);
      if (index === -1) {
        console.log('Could not find template', templateUuid);
        return;
      }
      Vue.delete(state.templates, index);
    },
    updateTemplate(state, template) {
      const index = state.templates.findIndex((i) => i.id == template.id);
      if (index === -1) {
        console.log('Could not find template', template);
        return;
      }
      Vue.set(state.templates, index, template);
    },
    setDataPickerDialogIsOpen(state, newValue: boolean) {
      Vue.set(state.datapickerDialog, 'isOpen', newValue);
    },
    favoriteTemplate(state, payload) {
      const index = state.templates.findIndex((i) => i.id == payload.templateId);
      if (index === -1) {
        console.log('Could not find template', payload.id);
        return;
      }
      Vue.set(state.templates[index], 'isFavorite', payload.isFavorite);
    },
    setSelectedDatapickerTemplate(state, value) {
      state.selectedTemplate = value;
      state.originalTemplate = cloneDeep(value);
    },
    addColumnToTemplate(state) {
      const newColumn = {
        value: NO_VALUE_FIELD,
      };
      state.selectedTemplate.fields.push(newColumn);
    },
    sanitizeSelectedTemplate(state) {
      const sanitizedFields = state.selectedTemplate.fields.reduce((acc, field) => {
        if (field.value !== NO_VALUE_FIELD) {
          acc.push(field);
        }
        return acc;
      }, []);

      state.selectedTemplate.fields = sanitizedFields;
    },
    changeColumnValueInTemplate(state, {originalField, field}) {
      if (!originalField) {
        const fieldIndexToChange = state.selectedTemplate.fields.findIndex((column) => column.value === NO_VALUE_FIELD);
        Vue.set(state.selectedTemplate.fields, fieldIndexToChange, field);
      } else {
        const fieldIndexToChange = state.selectedTemplate.fields.findIndex((column) => column.value === originalField.value);
        const updatedColumn = {
          ...state.selectedTemplate.fields[fieldIndexToChange],
          ...field,
        };
        Vue.set(state.selectedTemplate.fields, fieldIndexToChange, updatedColumn);
      }
    },
    changeColumnWidthInTemplate(state, {fieldName, width}) {
      const columnToUpdate = state.selectedTemplate.fields.find((column) => column.value === fieldName) || {};
      columnToUpdate.width = width;
      state.headerHeight = DEFAULT_HEADER_HEIGHT;
      state.columnWidthUpdated++;
    },
    changeColumnSortInTemplate(state, {fieldName, sort}) {
      const columnToUpdate = state.selectedTemplate.fields.find((column) => column.value === fieldName) || {};
      columnToUpdate.sort = sort;
    },
    changeColumnOrderInTemplate(state, newColumnOrder) {
      const newColPositions = state.selectedTemplate.fields.map((field) => {
        const fieldIndex = newColumnOrder.findIndex((column) => column.colId === field.value);
        field.position = fieldIndex;
        return field;
      });
      state.selectedTemplate.fields = newColPositions.sort((a, b) => a.position - b.position);
    },
    removeColumnFromTemplate(state, field: IHeader) {
      const indexToRemove = state.selectedTemplate.fields.findIndex((column) => column.value === field?.value ?? NO_VALUE_FIELD);
      state.selectedTemplate.fields.splice(indexToRemove, 1);
    },
    setFilterText(state, {colId, filterText}) {
      Vue.set(state.filterText, colId, filterText);
    },
    setColumnMenuType(state, columnMenuType) {
      state.columnMenuType = columnMenuType;
    },
    setSortedColumnId(state, colId) {
      state.sortedColumnId = colId;
    },
    setIsEditingTemplate(state, isEditingTemplate) {
      state.isEditingTemplate = isEditingTemplate;
    },
    setFocusedCell(state, focusedCell) {
      state.focusedCell = focusedCell;
    },
    setEditingCell(state, editingCell) {
      state.editingCell = editingCell;
    },
    setHeaderHeight(state, headerHeight) {
      state.headerHeight = headerHeight;
    },
  }
};
