import * as _ from 'lodash';
import {
  IActuator,
  ACTUATOR_RALDCS_DETAIL_STACKING_ORDER,
} from '@animtools/rdx-common';

export const actuatorsModule = {
  namespaced: true,

  state: {
    selectedActuatorIds: [] as Array<string>,
    sortByValue: ACTUATOR_RALDCS_DETAIL_STACKING_ORDER.value,
  },

  getters: {
    selectedActuators(state, getters, rootState): IActuator[] {
      const figureActuators: IActuator[] = rootState.figures.figureActuators;
      const filteredActuators = figureActuators.filter((actuator) =>
        state.selectedActuatorIds.includes(actuator.uuid),
      );
      return filteredActuators;
    },
    sortedActuators(state, getters, rootState): IActuator[] {
      return _.orderBy(
        rootState.figures.actuators.data,
        [state.sortByValue],
        ['asc'],
      );
    },
  },

  mutations: {
    setSelectedActuatorIds(state, selectedActuatorIds: string[]) {
      state.selectedActuatorIds = selectedActuatorIds;
    },
    removeSelectedActuatorId(state, idToRemove: string[]) {
        const idx = state.selectedActuatorIds.indexOf(idToRemove);
        if (idx > -1) {
            state.selectedActuatorIds.splice(idx, 1)
        }
      },
    setSortByValue(state, newValue) {
      state.sortByValue = newValue;
    },
  },
};
