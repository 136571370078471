<template>
  <v-snackbar
    class="downloads"
    v-model="show"
    light
    right
    timeout="-1"
    elevation="4"
    rounded="lg"
    content-class="container"
    width="300px"
  >
    <div class="d-flex justify-space-between mr-n2">
      <span>
        <span class="downloads__title">Downloads</span>
        <v-badge
          color="info"
          :content="completedDownloads + ' of ' + totalDownloads"
          inline />
      </span>

      <v-icon @click="toggleCollapse()" dense>
        {{ this.collapseIcon }}
      </v-icon>
    </div>

    <div v-show="!this.collapsed" class="downloads__container mr-n2 mt-1">
      <v-card
        v-for="download in downloads"
        :key="download.key"
        class="downloads__download pa-2 mt-2"
        elevation="0">

        <div class="d-flex justify-space-between">
          <div>
            <v-tooltip top z-index="1001">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <div>
                    {{ truncateFilename(download.file.name) }}
                  </div>
                </span>
              </template>
              <span>{{ download.file.name }}</span>
            </v-tooltip>
          </div>

          <div v-if="download.status === STATUS.downloading">
            {{ formatBytes(download.loadedSize) }}
            <span v-if="download.totalSize">/ {{ formatBytes(download.totalSize) }}</span>
          </div>

          <div v-else-if="download.status !== STATUS.error" class="status">
            {{ download.status }}
          </div>
        </div>

        <div class="d-flex justify-end">
          <v-progress-linear
            v-if="download.status !== STATUS.error"
            :indeterminate="download.progress == null && download.status === STATUS.downloading"
            class="align-self-center mr-1"
            :value="download.progress"
            color="blue-grey"
            height="16"
            rounded
          >
            <strong>{{ progress(download.progress) }}</strong>
          </v-progress-linear>

          <div v-if="download.error" style="width: 100%" class="error--text">
            Error: {{ download.error }}
          </div>

          <v-icon v-if="download.isCancelable" @click="cancelDownload(download.key)" size="20">
            mdi-close-octagon
          </v-icon>

          <v-icon v-if="isRedownloadable(download.status)" @click="redownload(download)" size="20">
            mdi-autorenew
          </v-icon>

          <v-icon v-if="isDeletable(download.status)" @click="deleteDownload(download.key)" size="20">
            mdi-close
          </v-icon>
        </div>
      </v-card>
    </div>
  </v-snackbar>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import figureFilesMixin from '../../mixins/figureFilesMixin';
import { formatBytes } from '../../util';
import { STATUS } from '../../store/modules/globalFileDownload';

const DELETABLE_STATES = [
  STATUS.canceled,
  STATUS.completed,
  STATUS.error,
];

export default {
  name: 'GlobalFileDownload',
  mixins: [figureFilesMixin],
  data() {
    return {
      maxFilenameLength: 20,
      collapsed: false,
      STATUS,
    };
  },

  computed: {
    ...mapState('globalFileDownload', ['downloads']),
    totalDownloads() {
      return Object.keys(this.downloads).length;
    },
    completedDownloads() {
      return Object.values(this.downloads)
        .filter((download) => DELETABLE_STATES.includes(download.status))
        .length;
    },
    show() {
      return this.totalDownloads > 0;
    },
    collapseIcon() {
      if (this.collapsed) {
        return 'mdi-chevron-up';
      }

      return 'mdi-chevron-down';
    },
  },

  methods: {
    ...mapActions('globalFileDownload', ['startDownload', 'cancelDownload']),
    ...mapMutations('globalFileDownload', ['deleteDownload', 'clearDownloads']),

    formatBytes,

    progress(progress) {
      if (progress != null) {
        return `${progress}%`;
      }
    },

    isDeletable(status) {
      return DELETABLE_STATES.includes(status);
    },

    isRedownloadable(status) {
      return [STATUS.canceled, STATUS.error].includes(status);
    },

    redownload(download) {
      this.startDownload({
        ...download,
        retries: 0,
      });
    },

    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
  },

  beforeDestroy() {
    this.clearDownloads();
  },
};
</script>

<style lang="scss">
.downloads {

  .v-icon {
    padding: 0;
  }

  &__title {
    font-weight: $font-bold;
    font-size: 16px;
    color: $dark-blue;
    text-align: center;
    position: relative;
  }

  &__container {
    max-height: 160px;
    overflow-y: scroll;
  }

  &__download {
    background-color: $lighter-blue !important;
    width: 100%;

    .status::first-letter {
      text-transform: capitalize;
    }
  }
}
</style>