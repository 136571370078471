export const FIGURE_INFO_HEADERS = [
  {text: 'Figure Name', value: 'name', type: 'string'},
  {text: 'Animation<br>Equipment Number', value: 'animationEquipmentNumber', type: 'string'},
  {text: 'Asset Name', value: 'assetName', type: 'string'},
  {text: 'Parks', value: 'parks', type: 'array',
    sort: (a, b) => {
      // parks are sorted first by the number of parks, then by alphabetical
      const lengthA = a?.length || 0;
      const lengthB = b?.length || 0;
      if (lengthA == lengthB) {
        return (a || '').toString().localeCompare((b || '').toString());
      } else {
        return lengthA - lengthB;
      }
    },
  },
  {text: 'Attraction', value: 'attraction', type: 'string'},
  {text: 'Scene', value: 'scene', type: 'string'},
  {text: 'Description', value: 'description', type: 'string', width: '30%'},
  {text: 'Image', value: 'image', type: 'image', entityType: 'figure'},
];

export const FIGURE_DETAIL_HEADERS = [
  {text: 'Project Repository', value: 'projectRepository', type: 'url'},
  {text: 'CAD Location', value: 'cadLocation', type: 'string'},
  {text: 'Simscape Model<br>Location', value: 'simscapeModelLocation', type: 'string'},
  {text: 'Control Software', value: 'controlSoftware', type: 'enum', options: ['Dancer', 'RAL', 'DCS', 'other']},
  {text: 'Control Software<br>Version', value: 'controlSoftwareVersion', type: 'string'},
  {text: 'PLC Name', value: 'plcName', type: 'string'},
  {text: 'ACS DGN<br>IP address', value: 'acsDgnIpAddress', type: 'string'},
  {text: 'EFC Cabinet<br>Number', value: 'efcCabinetNumber', type: 'string'},
  {text: 'Figure Name Prefix', value: 'namePrefix', type: 'string'},
];
