import { type IActuator, type IFunction } from "@animtools/rdx-common";

interface State {
  selectedFnId: string | null;
  selectedFnIds: string[];
}

export const functionsModule = {
  namespaced: true,

  state: {
    selectedFnId: null,
    selectedFnIds: [],
  } as State,

  getters: {
    allFnsId(state: State, getters, rootState): string[] {
      return (rootState.figures.figureFunctions as IFunction[]).map((fn) => fn.uuid);
    },

    selectedFns(state: State, getters, rootState): IFunction[] {
      const figureFns: IFunction[] = rootState.figures.figureFunctions;
      return figureFns
        .filter((figureFn) => state.selectedFnIds.includes(figureFn.uuid));
    },

    relatedFigures(): any[] {
      return [];
    },
  },

  actions: {
    getAssociatedFnActuators({rootState}, selectedFns: IFunction[]): IActuator[] {
      const figureActuators: IActuator[] = rootState.figures.figureActuators;
      if (!figureActuators?.length) {
        return [];
      }

      const actuatorIds = new Set<string>();
      const actuators: Pick<IActuator, 'uuid' | 'name'>[] = selectedFns
        .map(({actuators}) => actuators ?? [])
        .flat(1);
      actuators.forEach((actuator) => actuatorIds.add(actuator.uuid));

      return figureActuators
        .filter((figureActuator) => actuatorIds.has(figureActuator.uuid));
    },

    async getAssociatedFns(
      {dispatch, rootState},
      selectedFn: IFunction | null
    ): Promise<IFunction[]> {
      const fns: IFunction[] = rootState.figures.functions?.data;
      if (!fns?.length || !selectedFn) {
        return [];
      }

      const fnIds = new Set<string>();
      const actuators: IActuator[] = await dispatch('getAssociatedFnActuators', [selectedFn]);
      actuators.forEach((actuator) => {
        const actuatorFns: Pick<IFunction, 'uuid' | 'name'>[] = actuator.functions;
        actuatorFns.forEach((actuatorFn) => fnIds.add(actuatorFn.uuid));
      });

      return fns
        .filter((fn) => fnIds.has(fn.uuid))
        .sort((a, b) => a.channel - b.channel);
    },

    getRelatedFns({rootState}, fns: IFunction[]): IFunction[] {
      const figureFns: IFunction[] = rootState.figures.figureFunctions;

      if (!figureFns.length) {
        return [];
      }

      const relatedFns: IFunction[] = [];
      for (const fn of fns) {
        const fnName = fn.name
          .toLowerCase()
          .replace('left', '')
          .replace('right','');

        relatedFns.push(
          ...figureFns
            .filter(figureFn =>
              figureFn.uuid !== fn.uuid &&
              figureFn.name.toLowerCase().includes(fnName)
            )
        );
      }

      return relatedFns.sort((a, b) => a.channel - b.channel);
    },

    async setSelectedFn(
      {state, dispatch, commit}: {state: State, dispatch: any, commit: any},
      selectedFn: IFunction
    ): Promise<void> {
      if (state.selectedFnId === selectedFn.uuid) {
        commit('setSelectedFnId', null);
        commit('setSelectedFnIds', []);
        return;
      }
      const associatedFns: IFunction[] = await dispatch('getAssociatedFns', selectedFn);
      const selectedFnIds: string[] = associatedFns.map(({uuid}) => uuid);
      selectedFnIds.push(selectedFn.uuid);
      commit('setSelectedFnId', selectedFn.uuid);
      commit('setSelectedFnIds', selectedFnIds);
    },
  },

  mutations: {
    setSelectedFnIds(state: State, selectedFnIds: string[]) {
      state.selectedFnIds = selectedFnIds;
    },
    setSelectedFnId(state: State, selectedFnId: string) {
      state.selectedFnId = selectedFnId;
    },
  },
};
