export function popupWindow(url, title, w, h) {
  const y = window.outerHeight / 2 + window.screenY - ( h / 2);
  const x = window.outerWidth / 2 + window.screenX - ( w / 2);
  const options = 'width=' + w + ', height=' + h + ', top=' + y + ', left=' + x;
  return window.open(url, title, options);
}

export function stripHtmlTags(input) {
  if (!input) {
    return undefined;
  }
  return input.replaceAll('<br>', ' ');
}

export function deepCopy(source) {
  const result = JSON.parse(JSON.stringify(source));
  return result;
}

export function copyArrayOfObjects(input) {
  if (!input) {
    return [];
  }
  return input.map((item) => {
    Object.assign({}, item);
  });
}

// Recursively remove a key from object and object's children
export function removeKey(source, key) {
  const obj = Object.assign({}, source);

  for (const prop in obj) {
    if (prop === key) {
      delete obj[prop];
    }
    if (obj[prop] === null || Array.isArray(obj[prop])) {
      continue;
    } else if (typeof obj[prop] === 'object') {
      obj[prop] = removeKey(obj[prop], key);
    }
  }
  return obj;
}

export function toDecimal(value, digits = 2) {
  const pow = Math.pow(10, digits);
  return Math.round(value * pow) / pow;
}

export function isNumber(value) {
  return !isNaN(parseFloat(value));
}

export function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

// https://stackoverflow.com/a/23593099
export function getDate() {
  const currentDate = new Date();
  let month = '' + (currentDate.getMonth() + 1);
  let day = '' + currentDate.getDate();
  const year = currentDate.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
}

export function camelToTitle(input) {
  return input.replace(/([A-Z])/g, (match) => ` ${match}`)
      .replace(/^./, (match) => match.toUpperCase());
}

// https://codereview.stackexchange.com/questions/115885/extract-numbers-from-a-string-javascript
export function removeLetter(input) {
  return input.match(/\d+/g).map(Number)[0];
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const value = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return `${value} ${sizes[i]}`;
}

export function isString(data: unknown): data is string {
  return typeof data === 'string';
};