import api from '../../api';

export const safetyWarningModule = {
  namespaced: true,

  state: {
    safetyWarning: {
      dialog: false,
      isActive: false,
      message: null,
    },
  },

  actions: {
    getSafetyWarning({ commit }, { figureUuid }) {
      const type = 'warning';
      const step = 0;
      const slug = 'safety-warning';

      return api
        .getCheckoutHistory(figureUuid, type, step, slug)
        .then((response) => {
          const isActive = response.data[0]?.change?.verified;
          const message = response.data[0]?.message;
          commit('setSafetyWarning', { isActive, message });
        })
        .catch((error) => {
          console.error(error);
          commit('pushError', error, { root: true });
        });
    },
  },

  mutations: {
    setSafetyWarning(state, payload) {
      state.safetyWarning = {
        ...state.safetyWarning,
        ...payload,
      };
    },
  },
};
