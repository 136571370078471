import Vue from 'vue';
import Axios from 'axios';
import api from '../../api';

export const STATUS = {
  pending: 'pending',
  downloading: 'downloading',
  completed: 'completed',
  canceled: 'canceled',
  error: 'error',
};

/**
 * @typedef {Object} File
 * @property {number} id
 * @property {string} name
 */

/**
 * @typedef {Object} Download
 * @property {string} key
 * @property {'file' | 'asset' | 'figures-excel-sheet' | 'zip'} type
 * @property {File} file
 * @property {string} status
 * @property {import('../../api').DownloadZipPayload} requestPayload
 * @property {Boolean} isCancelable
 * @property {number | null} progress
 * @property {number | null} totalSize
 * @property {number | null} loadedSize
 * @property {import('axios').CancelTokenSource} cancelTokenSource
 * @property {string | null} error
 * @property {number} retries
 */

/**
 * @typedef {Object.<string, Download>} Downloads
 */

/**
 * @typedef {Object} State
 * @property {Downloads} downloads
 */

/**
 * @typedef {Object} Context
 * @property {State} state
 * @property {import('vuex').Dispatch} dispatch
 * @property {import('vuex').Commit} commit
 */

/**
 * @return {Downloads}
 */
function downloadsObject() {
  return {};
}

/** @type {import('vuex').Module<State, any>} */
export const globalFileDownloadModule = {
  namespaced: true,

  state: {
    downloads: downloadsObject(),
  },

  actions: {
    /**
     * @param {Context} context
     * @param {Object} payload
     * @param {Download["file"]} payload.file
     * @param {Download["requestPayload"]} payload.requestPayload Currently passed to the `api.downloadFilesInZip` method call
     * @param {Download["type"]} payload.type Allows to generate the corresponding download url and a unique key used in the `GlobalFileDownload` component.
     * @param {Download["retries"]} [payload.retries] Used to know if the download is a retry, since this action is performing one retry in case of an error.
     *
     * @returns {Promise<Blob | undefined>}
     */
    async startDownload(
        {commit, dispatch, state},
        {file, requestPayload, type, retries = 0},
    ) {
      const key = `${type}-${file.id}`;

      let download = state.downloads[key];
      // Prevents to have a repeated file downloading
      if ([STATUS.pending, STATUS.downloading].includes(download?.status) && retries === 0) {
        return;
      }

      download = {
        key,
        type,
        file,
        requestPayload,
        status: STATUS.pending,
        isCancelable: true,
        progress: null,
        totalSize: null,
        loadedSize: null,
        cancelTokenSource: Axios.CancelToken.source(),
        error: null,
        retries,
      };

      commit('createDownload', {key, download});

      /** @type {Blob | undefined} */
      const blob = await dispatch('requestDownload', download);

      if (blob) {
        await dispatch('downloadFile', {
          blob,
          filename: file.name,
        });

        commit('completedDownload', key);
      }
    },

    /**
     * @param {Context} context
     * @param {Download} download
     * @return {Promise<Blob | undefined>}
     */
    async requestDownload(
        {commit, dispatch},
        {key, file, requestPayload, type, retries, cancelTokenSource},
    ) {
      /** @type {import('axios').AxiosResponse<Blob>} */
      let response;
      /** @type {import('axios').AxiosRequestConfig} */
      const axiosRequestConfig = {
        // In this way is not necessary to transform from stream to blob type
        responseType: 'blob',
        // No timeout for file download, otherwise it could cancel an ongoing one
        timeout: 0,
        cancelToken: cancelTokenSource.token,
        onDownloadProgress: (event) => {
          commit('setDownloadProgress', {
            key,
            totalSize: event.total,
            loadedSize: event.loaded,
          });
        },
      };

      switch (type) {
        case 'file':
          response = await api.getFile(file.id, axiosRequestConfig);
          break;
        case 'asset':
          response = await api.downloadAsset(file.id, axiosRequestConfig);
          break;
        case 'figures-excel-sheet':
          response = await api.figureExcelSheet(file.id, axiosRequestConfig);
          break;
        case 'zip':
          response = await api.downloadFilesInZip(
              requestPayload,
              axiosRequestConfig,
          );
          break;
        default:
          throw new Error(`Unknown download type '${type}'.`);
      }

      if (Axios.isAxiosError(response)) {
        // Only retries the download one time in case of any error in the request
        if (retries === 0) {
          dispatch('startDownload', {file, requestPayload, type, retries: 1});
        } else {
          commit('downloadError', {key, message: response.message});
        }
        return;
      }

      if (Axios.isCancel(response)) {
        commit('cancelDownload', key);
        return;
      }

      return response.data;
    },

    /**
     * Performs the actual download of the file in the browser.
     *
     * @param {Context} context
     * @param {Object} payload
     * @param {Blob} payload.blob
     * @param {string} payload.filename
     */
    downloadFile(context, {blob, filename}) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
      link.remove();
    },

    cancelDownload({state}, key) {
      const {cancelTokenSource} = state.downloads[key];

      cancelTokenSource.cancel('Operation canceled by the user.');
    },
  },

  mutations: {
    /**
     * @param {State} state
     * @param {Object} payload
     * @param {string} payload.key
     * @param {Download} payload.download
     */
    createDownload(state, {key, download}) {
      Vue.set(state.downloads, key, download);
    },

    /**
     * @param {State} state
     * @param {string} key
     */
    completedDownload(state, key) {
      const oldDownload = state.downloads[key];

      Vue.set(state.downloads, key, {
        ...oldDownload,
        status: STATUS.completed,
        progress: 100,
        isCancelable: false,
      });
    },

    /**
     * @param {State} state
     * @param {string} key
     */
    cancelDownload(state, key) {
      const oldDownload = state.downloads[key];

      Vue.set(state.downloads, key, {
        ...oldDownload,
        status: STATUS.canceled,
        isCancelable: false,
      });
    },

    /**
     * @param {State} state
     * @param {string} key
     */
    deleteDownload(state, key) {
      Vue.delete(state.downloads, key);
    },

    /**
     * @param {State} state
     * @param {Object} payload
     * @param {string} payload.key
     * @param {number} payload.totalSize
     * @param {number} payload.loadedSize
     */
    setDownloadProgress(state, {key, totalSize, loadedSize}) {
      const oldDownload = state.downloads[key];
      // `completed` mutation sets the 100% of progress
      const progress =
        totalSize !== 0 ? Math.floor((loadedSize / totalSize) * 99) : null;

      Vue.set(state.downloads, key, {
        ...oldDownload,
        status: STATUS.downloading,
        progress,
        totalSize,
        loadedSize,
        isCancelable: true,
      });
    },

    /**
     * @param {Object} state
     * @param {Downloads} state.downloads
     */
    clearDownloads(state) {
      state.downloads = {};
    },

    /**
     * @param {State} state
     * @param {Object} payload
     * @param {string} payload.key
     * @param {string} payload.message
     */
    downloadError(state, {key, message}) {
      const oldDownload = state.downloads[key];

      Vue.set(state.downloads, key, {
        ...oldDownload,
        status: STATUS.error,
        error: message,
        isCancelable: false,
      });
    },
  },
};
