import api from '../../api';

export const chipsModule = {
  namespaced: true,

  state: {
    chips: [],
  },

  actions: {
    getAllChips({commit}) {
      return api
          .getAllChips()
          .then((response) => {
            commit('setChips', response.data);
          })
          .catch((error) => {
            console.log(error);
            commit('pushError', error, {root: true});
          });
    },

    chipAdd({commit}, chip) {
      return api
          .addChips(chip)
          .then((response) => {
            commit('addChip', response.data);
          })
          .catch((error) => {
            console.log(error);
            commit('pushError', error, {root: true});
          });
    },

    chipDelete({commit}, chip) {
      return api
          .deleteChip(chip.uuid)
          .then(() => {
            commit('deleteChip', chip);
          })
          .catch((error) => {
            console.log(error);
            commit('pushError', error, {root: true});
          });
    },
  },

  mutations: {
    setChips(state, array) {
      state.chips = array;
    },
    addChip(state, array) {
      state.chips = [...state.chips, ...array];
    },
    deleteChip(state, chip) {
      state.chips = state.chips.filter((c) => c.uuid !== chip.uuid);
    },
  },
};
