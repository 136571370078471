import Vue from 'vue';
import Router from 'vue-router';
import store from './store/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: '/',
    //   name: 'root',
    //   component: () => import('./views/landing/Landing.vue'),
    //   meta: {title: 'RDX Web'},
    // },
    {
      path: '/',
      name: 'root',
      redirect: {name: 'figures'},
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('./views/landing/Landing.vue'),
      meta: {title: 'RDX Web'},
    },
    {
      path: '/tokens',
      name: 'tokens',
      component: () => import('./views/tokens/Tokens.vue'),
      meta: {title: 'Tokens'},
    },
    {
      path: '/hardware/motors/:hardwareId?',
      alias: '/hardware',
      name: 'motors',
      component: () => import('./views/hardware/Motors.vue'),
      meta: {title: 'Motors'},
    },
    {
      path: '/hardware/encoders/:hardwareId?',
      name: 'encoders',
      component: () => import('./views/hardware/Encoders.vue'),
      meta: {title: 'Encoders'},
    },
    {
      path: '/hardware/drives/:hardwareId?',
      name: 'drives',
      component: () => import('./views/hardware/Drives.vue'),
      meta: {title: 'Drives'},
    },
    {
      path: '/hardware/gearboxes/:hardwareId?',
      name: 'gearboxes',
      component: () => import('./views/hardware/Gearboxes.vue'),
      meta: {title: 'Gearboxes'},
    },
    {
      path: '/hardware/reviews',
      name: 'reviews',
      component: () => import('./views/hardware/Reviews.vue'),
      meta: {title: 'Reviews'},
    },
    {
      path: '/figure/:figureId',
      name: 'figureFiles',
      component: () => import('./views/figures/v2/Figure.vue'),
      meta: {title: 'Figure Files'},
    },
    {
      path: '/figure/history/:figureId',
      name: 'figyreHistory',
      component: () => import('./views/figures/v2/FigureHistory.vue'),
      meta: {title: 'Figure History'},
    },
    {
      path: '/figure/checkout/:figureId',
      name: 'FigureCheckoutView',
      component: () => import('./views/figures/v2/FigureCheckout.vue'),
      meta: {title: 'Checkout Wizard'},
    },
    {
      path: '/figure/viewer/:fileId',
      name: 'Viewer',
      component: () => import('./views/figures/v2/Viewer.vue'),
      meta: {title: 'Viewer'},
    },
    {
      path: '/figure',
      component: () =>
        import('./views/figures/v2/FigureActuatorsFunctions.vue'),
      children: [
        {
          path: '',
          redirect: '/figures',
        },
        {
          path: 'actuators/:figureId',
          name: 'figure/actuators',
          component: () => import('./views/figures/v2/ActuatorsPage.vue'),
          meta: {title: 'Actuators'},
        },
        {
          path: 'functions/:figureId',
          name: 'figure/functions',
          component: () => import('./views/figures/v2/FunctionsPage.vue'),
          meta: {title: 'Functions'},
        },
      ],
    },
    {
      path: '/figures',
      name: 'figures',
      component: () => import('./views/figures/Figures.vue'),
      meta: {title: 'Figures'},
    },
    {
      path: '/loggedin',
      name: 'loggedin',
      component: () => import('./views/LoggedIn.vue'),
      meta: {title: ''},
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('./views/admin/Admin.vue'),
      meta: {title: 'Admin', adminsOnly: true},
    },
    {
      path: '/admin/users/:userId',
      name: 'admin/user',
      component: () => import('./views/admin/user/User.vue'),
      meta: {title: 'User', adminsOnly: true},
    },
    {
      path: '/file/bulk',
      name: 'BulkFile',
      component: () => import('./views/file/File.vue'),
      meta: {
        title: 'File',
        bulk: true,
      },
    },
    {
      path: '/file/download/:fileId',
      name: 'File',
      component: () => import('./views/file/File.vue'),
      meta: {title: 'File'},
    },
    {
      path: '/unauthorized',
      name: 'Unauthorized',
      component: () => import('./views/Unauthorized.vue'),
    },
    {
      path: '*',
      component: () => import('./views/NotFound.vue'),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch('users/loadCurrentUser');
  if (to.meta.adminsOnly) {
    if (!store.state.users.isAdmin && !store.state.users.isFigureAdmin && !store.state.users.isProjectAdmin) {
      next('/unauthorized');
      return;
    }
  }
  next();
});

export default router;